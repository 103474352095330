import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form, ErrorMessage } from 'formik';
import { FormikField } from 'components';
import '../../slices/SignUpForm/styles.scss';
import './styles.scss';

const NewsletterSignup = () => {
  const [submitted, setSubmitted] = useState(false);

  const initialValues = {
    email: '',
  };

  const fields = [
    {
      type: 'email',
      name: 'email',
      placeholder: 'Enter your email',
      label: 'Pop your email here, hit enter and we’ll send you some cool stuff',
    },
  ];

  const validation = values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    return errors;
  };

  const encode = formData =>
    Object.keys(formData)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`)
      .join('&');

  const onSubmit = async (values, { setSubmitting }) => {
    await axios.post(
      'https://a5w3o6c2j1.execute-api.ap-southeast-2.amazonaws.com/dev?format=json',
      encode({
        ...values,
        _to: '949a3eae81831d047ddc009d75bd90633f4d',
        _bcc: '8f9e3f8299ac1e0d7cc814d27bbf923936163d13',
        _sender: 'Lemmy',
        _formname: 'New newsletter signup from Lemmy.com.au',
        _replyTo: values.email,
      }),
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );
    setSubmitting(false);
    setSubmitted(true);
  };

  return (
    <>
      <section className="form-container newsletter-signup">
        {submitted ? (
          <p>Thank you for joining the Lemmy newsletter, keep an eye on your inbox</p>
        ) : (
          <Formik initialValues={initialValues} validate={validation} onSubmit={onSubmit}>
            {({ isSubmitting, errors, handleChange }) => (
              <Form>
                {fields.map(field => {
                  const hasError = errors[field.name] && submitted;
                  return (
                    <div className="form-field">
                      <p>{field.label}</p>
                      <FormikField {...field} handleChange={handleChange} />
                      <div className={`validation-error ${hasError ? 'active' : 'inactive'}`}>
                        {submitted && <ErrorMessage name={field.name} component="div" />}
                      </div>
                    </div>
                  );
                })}
              </Form>
            )}
          </Formik>
        )}
      </section>
    </>
  );
};

export default NewsletterSignup;
