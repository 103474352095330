import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const Link = props => {
  const {
    children,
    className,
    activeClassName = 'active',
    to,
    title,
    target = '_blank',
    onClick = () => {},
    ...otherProps
  } = props;

  const lemmyUrl = 'https://lemmy.com.au';

  const isExternal = (to && to.indexOf('http') !== -1) || (to && to[0] === '#');
  const isLemmyLink = to && to.indexOf(lemmyUrl) !== -1;

  if (isExternal && !isLemmyLink) {
    return (
      <a
        href={to}
        className={className || ''}
        title={title || null}
        target={target}
        onClick={onClick}
        rel="nofollow noopener noreferrer"
        {...otherProps}
      >
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      to={isLemmyLink ? to.replace(lemmyUrl, '') : to}
      className={className || ''}
      activeClassName={activeClassName}
      title={title || null}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;
