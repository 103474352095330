import React from 'react';
import { Footer, HeaderNav, SEO } from 'components';
import 'typeface-poppins';
import 'typeface-oswald';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = props => {
  const { children, seo, location } = props;

  return (
    <>
      <HeaderNav location={location} />
      <main>
        <SEO {...seo} />
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
