import React from 'react';
import { Link } from 'gatsby';
import { Image } from 'components';
import { dateFormatter } from '../../utils/helpers';
import './styles.scss';

const BlogCard = props => {
  const { data, uid: slug } = props;
  const {
    blog_post_title: postTitle,
    hero_image: heroImage,
    hero_background_color: backgroundColor,
    post_excerpt: excerpt,
    date_published: datePublished,
  } = data;

  return (
    <Link to={`/blog/${slug}`} className="blog-card">
      <section className="blog-card-wrapper">
        <p className="blog-card-date">
          <span>LEMMY SAID ON </span>
          {dateFormatter(datePublished)}
        </p>
        <div className="blog-card-bg-color" style={{ backgroundColor }}>
          <div className="blog-card-image-container">
            <Image image={heroImage} className="image-box" />
          </div>
        </div>
        {postTitle && <h1 className="blog-card-title">{postTitle.text}</h1>}
        {excerpt && <p className="blog-card-excerpt">{excerpt.text}</p>}
        <button type="button" className="button">Read more</button>
      </section>
    </Link>
  );
};

export default BlogCard;
