import React, { useState, useEffect } from 'react';
import { Image } from 'components';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import ScrollLock from 'react-scrolllock';
import OnVisible from 'react-on-visible';
import './styles.scss';

const FullScreenSlider = props => {
  const { data } = props;
  const { items } = data;

  const [lockedId, setLockedId] = useState(false);

  useEffect(() => {
    if (lockedId) {
      setTimeout(() => {
        setLockedId(false);
      }, 1000);
    }
  }, [lockedId]);

  const handleLockSlide = (isVisible, itemId) => {
    if (isVisible) {
      scrollToStart(itemId);
      setLockedId(itemId);
    }
  };

  const scrollToStart = id => {
    const element = document.getElementById(id);
    if (element) {
      smoothScrollIntoView(element, { behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="full-screen-slider" id="full-screen-slider-wrapper">
      {items.map((item, index) => {
        const { title, background_color: backgroundColor, content, image } = item;
        const hasTitle = title && title.html;
        const titleText = title.text;
        const hasContent = content && content.text;
        const hasImage = (image && image.url) || (image && image.localFile);
        const itemId = titleText
          ? titleText
            .toLowerCase()
            .split(' ')
            .join('-')
          : `id_${index}`;

        return (
          <OnVisible
            className="full-screen-slider-item"
            id={itemId}
            style={{ backgroundColor }}
            percent={100}
            onChange={isVisible => handleLockSlide(isVisible, itemId)}
          >
            <div className="wrapper">
              <div className="full-screen-slider-content">
                <span>
                  {hasTitle && (
                    <div className="full-screen-slide-title" dangerouslySetInnerHTML={{ __html: item.title.html }} />
                  )}
                </span>
                <p>{hasContent && item.content.text}</p>
              </div>
              {/* position fixed until next slide visible */}
              <div className="full-screen-slider-image">
                <Image image={hasImage && image} />
              </div>
            </div>
            <ScrollLock isActive={lockedId} />
          </OnVisible>
        );
      })}
    </div>
  );
};

export default FullScreenSlider;
