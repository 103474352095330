import React from 'react';
import { Image, Link } from 'components';
import './styles.scss';

const TwoColsText = props => {
  const { data } = props;
  const { items, primary } = data;
  const { custom_class: customClass } = primary;

  return (
    <section className={`two-cols-text ${customClass.text}`}>
      <div className="wrapper">
        {items.map((column, index) => (
          <TextColsColumn key={column.image.url || column.title.text || index} {...column} />
        ))}
      </div>
    </section>
  );
};

// individual collumns

const TextColsColumn = ({
  title,
  image,
  link_text: linkText,
  link,
  content,
  content_type: contentType,
  background_color: backgroundColor,
}) => {
  const hasImage = image && (image.localFile || image.url);
  const hasTitle = title && title.text && title.text.length > 2;
  const hasContent = content && content.html;
  const hasLinkText = linkText && linkText.text;

  const contentClass = contentType
    .split(' ')
    .join('-')
    .toLowerCase();

  const hasText = hasTitle || hasContent;

  return (
    <div className={`two-cols-text-column ${hasImage ? 'has-image' : ''}`} style={{ backgroundColor }}>
      {hasImage && (
        <div className="two-cols-text-image">
          <Image image={image} />
        </div>
      )}
      {hasText && (
        <div className="two-cols-text-container">
          {hasTitle && (
            <div className="two-cols-text-title">
              <h3 className="two-cols-text-title">{title.text}</h3>
            </div>
          )}
          {hasContent && (
            <div
              dangerouslySetInnerHTML={{ __html: content.html }}
              className={`two-cols-text-content ${contentClass}`}
            />
          )}
          {hasLinkText && (
            <Link to={`${link.url || '#'}`} className="button two-cols-text-button-container">
              {linkText.text}
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default TwoColsText;
