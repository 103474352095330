import React from 'react';
import { Image } from 'components';
import './styles.scss';

const SvgAndText = props => {
  const { data } = props;
  const { primary } = data;
  const { image, title } = primary;

  return (
    <section className="svg-and-text">
      <div className="wrapper">
        <div className="svg-and-text-container">
          <Image image={image} className="svg-and-text-image" />
          <div dangerouslySetInnerHTML={{ __html: title.html }} className="svg-and-text-title" />
        </div>
      </div>
    </section>
  );
};

export default SvgAndText;
