import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import OnVisible from 'react-on-visible';
import { Link, Image, NewsletterSignup } from 'components';
import copy from '../../images/copy.svg';
import seoAndBox from '../../images/seoAndBox.svg';
import brandAndCursor from '../../images/brandAndCursor.svg';
import instagramIcon from '../../images/instagram.svg';
import facebookIcon from '../../images/facebook.svg';
import pinterestIcon from '../../images/pinterest.svg';
import linkedinIcon from '../../images/linkedin.svg';
import './styles.scss';

const socialsQuery = graphql`
  query {
    ...GlobalSocials
  }
`;

const footerNavLinks = [
  { to: '/branding', text: 'Branding' },
  { to: '/packaging-design', text: 'Packaging' },
  { to: '/projects', text: 'Our Work' },
  { to: '/blog', text: 'Lemmy Blog' },
  { to: '/contact-us', text: 'Contact' },
];

const Footer = () => {
  const { prismicSettings } = useStaticQuery(socialsQuery);
  const { data: globalData } = prismicSettings;
  const { facebook, instagram, linkedin, pinterest } = globalData;

  const hasFacebook = facebook && facebook.url;
  const hasInstagram = instagram && instagram.url;
  const hasLinkedIn = linkedin && linkedin.url;
  const hasPinterest = pinterest && pinterest.url;

  return (
    <OnVisible className="footer">
      <footer className="wrapper footer-wrapper">
        {/* <Logo /> */}
        <div className="footer-icons">
          <img src={copy} alt="copywriting services" className="copy" />
          <img src={brandAndCursor} alt="branding services" className="brand-and-arrow" />
          <img src={seoAndBox} alt="seo services" className="seo-and-box" />
        </div>
        <div className="footer-sitemap">
          {/* footer nav links */}
          <div className="footer-navigation-links">
            {footerNavLinks.map(link => (
              <Link to={link.to} key={link.to} className="footer-link">
                {link.text}
              </Link>
            ))}
          </div>
          {/* contact details and newsletter */}
          <ul className="contact-lemmy">
            <li className="lemmy-number">02 49131459</li>
            <li className="lemmy-email">hello@lemmy.com.au</li>
            <li>2 Dick Street, Newcastle</li>
            <li>West NSW 2302</li>
          </ul>
          <div className="newsletter-signup">
            <NewsletterSignup />
            <div className="footer-social-icons">
              <Link to={hasInstagram}>
                <Image image={{ url: instagramIcon }} alt="instagram" className="footer-social-icon" />
              </Link>
              <Link to={hasFacebook}>
                <Image image={{ url: facebookIcon }} alt="facebook" className="footer-social-icon" />
              </Link>
              <Link to={hasPinterest}>
                <Image image={{ url: pinterestIcon }} alt="pinterest" className="footer-social-icon" />
              </Link>
              <Link to={hasLinkedIn}>
                <Image image={{ url: linkedinIcon }} alt="linkedin" className="footer-social-icon" />
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </OnVisible>
  );
};

export default Footer;
