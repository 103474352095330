import React from 'react';
import { Image, Link, Carousel } from '../../components';
import './styles.scss';

const Columns = ({ data }) => {
  const { primary, items } = data;
  const { use_slider: useSlider, background: backgroundColor } = primary;
  return (
    <section
      className={`three-columns ${useSlider === 'Yes' ? 'with-slider' : 'without-slider'}`}
      style={backgroundColor ? { backgroundColor } : {}}
    >
      <div className="wrapper">
        <SliderContainer useSlider={useSlider}>
          {items.map(item => (
            <Column key={item.title.text} {...item} />
          ))}
        </SliderContainer>
      </div>
    </section>
  );
};

const SliderContainer = ({ useSlider, children }) => {
  const className = 'three-columns-container';

  const responsive = {
    0: {
      items: 1,
    },
    769: {
      items: 2,
    },
  };

  if (useSlider === 'Yes') {
    return (
      <Carousel
        containerClassName={className}
        enabledBetween={[0, 1024]}
        responsive={responsive}
        preventEventOnTouchMove={false}
        autoHeight
        buttonsDisabled
        infinite
      >
        {children}
      </Carousel>
    );
  }

  return <div className={className}>{children}</div>;
};

const Column = ({ image, icon, link, button, title, content }) => {
  const hasImage = image && (image.localFile || image.url);
  const hasTitle = title && title.html && title.html.length > 2;
  const hasContent = content && content.html && content.html.length > 2;
  const hasLink = link && link.url;
  const hasButton = button && button.text && hasLink;
  const Container = hasLink ? Link : 'div';

  return (
    <Container to={hasLink || null} className={`three-columns-column ${icon && icon.url ? 'with-icon' : ''}`}>
      {hasImage && (
        <div className="three-columns-image">
          <Image image={image} alt={title.text || 'Lemmy'} />
        </div>
      )}
      {icon && icon.url && <img className="three-columns-icon" src={icon.url} alt={title.text || 'Lemmy'} />}
      {(hasTitle || hasContent) && (
        <div className="column">
          {hasTitle && <div className="three-columns-title" dangerouslySetInnerHTML={{ __html: title.html }} />}
          {hasContent && <div className="three-columns-content" dangerouslySetInnerHTML={{ __html: content.html }} />}
        </div>
      )}
      {hasButton && (
        <button type="button" className="button">
          {button.text}
        </button>
      )}
    </Container>
  );
};

export default Columns;
