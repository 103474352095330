import React from 'react';
import './styles.scss';

const SectionTitle = props => {
  const { data } = props;
  const { primary } = data;
  const { title } = primary;

  const hasValue = title && title.html && title.html.length > 2;

  if (!hasValue) return null;

  return (
    <section className="section-title">
      <div className="wrapper">
        <div className="section-title-text" dangerouslySetInnerHTML={{ __html: title.html }} />
      </div>
    </section>
  );
};

export default SectionTitle;
