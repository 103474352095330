import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { BlogCard } from 'components';
import './styles.scss';

const FeaturedPosts = ({ location }) => {
  const {
    posts: { nodes: posts },
  } = useStaticQuery(blogPostsQuery);

  const { pathname } = location;

  const filteredPosts = (posts || []).filter(post => pathname.indexOf(post.uid) === -1);
  const cappedPosts = filteredPosts.slice(0, 3);

  return (
    <div className="wrapper featured-posts-wrapper">
      <div className="blog-card-grid">
        {cappedPosts.length > 0 && cappedPosts.map(post => <BlogCard data={post.data} uid={post.uid} key={post.id} />)}
      </div>
    </div>
  );
};

export default FeaturedPosts;

const blogPostsQuery = graphql`
  query {
    posts: allPrismicBlogPost(sort: { order: ASC, fields: last_publication_date }, limit: 4) {
      nodes {
        id
        uid
        data {
          blog_post_title {
            text
          }
          hero_background_color
          hero_image {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          post_excerpt {
            text
          }
          date_published
        }
      }
    }
  }
`;
