import React from 'react';
import { Image, Link, Arrow } from 'components';
import './styles.scss';

const GridTwoCols = props => {
  const { data, location } = props;
  const { primary, items = [] } = data;
  const { shape, custom_class: customClass } = primary;

  return (
    <section className={`grid-two-cols ${shape.toLowerCase()} ${customClass ? customClass.text : ''}`}>
      <div className="wrapper">
        {items.map(column => (
          <GridColumn key={column?.tagline?.text} location={location} {...column} />
        ))}
      </div>
    </section>
  );
};

// individual collumns

const GridColumn = ({
  tagline,
  title,
  subtitle,
  image,
  worked_with: workedWith,
  background,
  button,
  link,
  location,
}) => {
  const hasImage = image && (image.localFile || image.url);
  const hasWorkedWith = workedWith && (workedWith.url || workedWith.localFile);
  const hasTitle = title && title.html;
  const hasSubtitle = subtitle && subtitle.text;
  const hasContent = tagline && tagline.text;
  const hasLink = link && link.url;
  const hasButton = button && button.text && hasLink;

  const Element = hasLink ? Link : 'div';
  const revealContent = hasImage && (hasContent || hasButton) ? 'reveal-content' : '';

  return (
    <Element
      to={hasLink}
      state={hasLink ? { prevPath: location.pathname } : null}
      className={`grid-two-cols-column ${revealContent}`}
      style={{ backgroundColor: background || '#FFFFFF' }}
    >
      {hasImage && (
        <div className="grid-two-cols-image">
          <Image image={image} />
        </div>
      )}
      {(hasTitle || hasContent) && (
        <div className="grid-two-cols-content">
          <div className="grid-two-cols-title" dangerouslySetInnerHTML={{ __html: hasTitle }} />
          {hasSubtitle && (
            <p>
              <strong>{hasSubtitle}</strong>
            </p>
          )}
          {hasContent && <p>{hasContent}</p>}
          {hasWorkedWith && (
            <div className="grid-two-cols-worked-with">
              <p>WORKED WITH</p>
              <Image image={workedWith} />
            </div>
          )}
          {hasButton && <Arrow label={button.text} alignLeft />}
        </div>
      )}
    </Element>
  );
};

export default GridTwoCols;
