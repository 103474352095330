import React from 'react';
import { Link } from 'gatsby';
import { Image } from 'components';
import './styles.scss';

const TwoByFour = props => {
  const { data } = props;
  const { primary, items } = data;
  const { title } = primary;

  const hasTitle = title && title.html;

  return (
    <section className="two-by-four">
      <div className="wrapper">
        {hasTitle && <div className="two-by-four-title" dangerouslySetInnerHTML={{ __html: title.html }} />}
        <div className="two-by-four-grid">
          {items.map(item => {
            const { brand_name: brandName, image, link } = item;
            const hasImage = image && (image.url || image.localFile);
            const hasBrandName = brandName && brandName.text;
            const revealContent = hasImage && hasBrandName ? 'reveal-content' : '';
            const hasLink = link?.url;

            return (
              <Link to={hasLink || '#'}>
                <div className={`two-by-four-item ${revealContent}`} key={item.brand_name}>
                  {hasImage && <Image image={item.image} className="two-by-four-image" />}
                  {hasBrandName && <p className="two-by-four-brand-name">{item.brand_name.text}</p>}
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TwoByFour;
