import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import { Formik, Form, ErrorMessage } from 'formik';
import { Arrow, FormikField, Link } from 'components';
import './styles.scss';

const NewsletterSignup = props => {
  const { data } = props;
  const { primary } = data;
  const { form_content: formContent, form_title: formTitle } = primary;

  const [submitted, setSubmitted] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [redirect, setRedirect] = useState(false);

  // thank you page redirect on submission
  useEffect(() => {
    if (redirect) {
      navigate('/form-submit-confirmation');
    }
  }, [redirect]);

  const hasContent = formContent && formContent.text;
  if (!hasContent) return null;

  const hasTitle = formTitle && formTitle.html;
  if (!hasTitle) return null;

  const arrowLabel = 'SIGN UP';
  if (!arrowLabel) return null;

  const initialValues = {
    name: '',
    company: '',
    email: '',
    phone: '',
    details: '',
    budget: '',
    services: '',
    reference: '',
  };

  const stepOneFields = [
    { type: 'text', name: 'name', placeholder: 'Your name', label: 'What is your name?*' },
    { type: 'text', name: 'company', placeholder: 'Enter your Company', label: 'Where do you work?' },
    { type: 'email', name: 'email', placeholder: 'Enter your email', label: 'What is your email address?' },
    { type: 'text', name: 'phone', placeholder: 'Enter your Phone number', label: 'What is your best contact number?' },
  ];

  const stepTwoFields = [
    {
      type: 'textarea',
      name: 'details',
      placeholder:
        'Give us a brief summary of your product or idea, your project requirements, and who you do/intent to sell to.',
      label: 'Describe the project*',
      required: true,
    },
    {
      type: 'radio',
      name: 'budget',
      label: 'Please offer your indicative budget for this project?*',
      options: [
        '$3,000 - $5,000',
        '$5,000 - $10,000',
        '$10,000 - $20,000',
        '$20,000 - $50,000',
        '$50,000+',
        'I don’t know my budget',
      ],
    },
  ];

  const stepThreeFields = [
    {
      type: 'checkbox',
      name: 'services',
      label: 'Select all our services you may need help with*',
      options: [
        'Market research',
        'Brand strategy',
        'Brandmark/logo ',
        'Packaging design',
        'Photograph style guide',
        'Social media strategy',
        'Copywriting',
        ' Illustrations',
        'Point of sale',
        'eCommerce',
        'other - specify',
      ],
    },
    {
      type: 'checkbox',
      name: 'reference',
      label: 'How’d you hear about Lemmy?*',
      options: ['Instagram', 'LinkedIn', 'Referred by a friend/colleague', 'Google', 'Facebook', 'Pinterest'],
    },
  ];

  const steps = [
    {
      title: 'Tell us about a bit about you',
      content:
        'Fill us in on your project and we’ll get back to you same day. Alternatively, call the studio on 02 49131459 or email us at hello@lemmy.com.au,',
      fields: stepOneFields,
    },
    {
      title: 'Tell us about your project',
      fields: stepTwoFields,
    },
    {
      title: 'Tell us the what & how?',
      fields: stepThreeFields,
    },
  ];

  const activeStep = steps[stepIndex];

  const validation = values => {
    const errors = {};

    const firstPage = stepIndex === 0;
    const secondPage = stepIndex === 1;
    const thirdPage = stepIndex === 2;

    // First page validation
    if (firstPage) {
      if (!values.name) {
        errors.name = 'Name is required';
      } else if (!values.email) {
        errors.email = 'Email is required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
      } else if (!values.phone) {
        errors.phone = 'Invalid phone number';
      } else if (!values.company) {
        errors.company = 'Please enter a company';
      }
    }

    // Second page validation
    if (secondPage) {
      if (!values.details) {
        errors.details = 'Please enter some details';
      } else if (!values.budget) {
        errors.budget = 'Please select a budget';
      }
    }

    // Third page validation
    if (thirdPage) {
      if (!values.services) {
        errors.services = 'Please select required services';
      } else if (!values.reference) {
        errors.reference = 'Please select a reference';
      }
    }

    return errors;
  };

  const onBack = event => {
    event.preventDefault();
    return setStepIndex(stepIndex - 1);
  };

  const encode = formData =>
    Object.keys(formData)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`)
      .join('&');

  const onSubmit = async (values, { setSubmitting }) => {
    if (stepIndex === steps.length - 1) {
      await axios.post(
        'https://a5w3o6c2j1.execute-api.ap-southeast-2.amazonaws.com/dev?format=json',
        encode({
          ...values,
          _to: '949a3eae81831d047ddc009d75bd90633f4d',
          _bcc: '8f9e3f8299ac1e0d7cc814d27bbf923936163d13',
          _sender: 'Lemmy',
          _formname: 'New enquiry from Lemmy.com.au',
          _replyTo: values.email,
        }),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      );
      setSubmitting(false);
      setSubmitted(true);
      setRedirect(true);
    } else {
      setStepIndex(stepIndex + 1);
      setSubmitting(false);
      setSubmitted(false);
    }
  };

  const submitButtonText = stepIndex === steps.length - 1 ? 'Submit' : 'Next';

  return (
    <>
      <section className="form-container">
        <div className="wrapper form-wrapper">
          {/* <span>{hasTitle && <div className="title" dangerouslySetInnerHTML={{ __html: formTitle.html }} />}</span> */}
          <div className="form-title">{activeStep.title}</div>
          <p className="form-content">{activeStep.content}</p>
          <Formik initialValues={initialValues} validate={validation} onSubmit={onSubmit}>
            {({ isSubmitting, errors, handleChange }) => (
              <Form>
                {activeStep.fields.map(field => {
                  const hasError = errors[field.name] && submitted;
                  return (
                    <div className="form-field">
                      <p>{field.label}</p>
                      <FormikField {...field} handleChange={handleChange} />
                      <div className={`validation-error ${hasError ? 'active' : 'inactive'}`}>
                        {submitted && <ErrorMessage name={field.name} component="div" />}
                      </div>
                    </div>
                  );
                })}
                <div className="form-actions">
                  <button type="submit" className="button" disabled={isSubmitting} onClick={() => setSubmitted(true)}>
                    <Arrow label={submitButtonText} />
                  </button>
                  {stepIndex !== 0 && (
                    <Link to="#back" className="form-go-back" onClick={onBack}>
                      GO BACK
                    </Link>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default NewsletterSignup;
