export const dateFormatter = date => {
  const months = [
    'January',
    'February',
    'March',
    'Arpil',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const jsDate = new Date(date);
  return `${jsDate.getDate()} ${months[jsDate.getMonth()]} ${jsDate.getFullYear()}`;
};
