import React from 'react';
import OnVisible from 'react-on-visible';
import { BlogCard } from 'components';
import './styles.scss';

const AllPosts = props => {
  const { posts, onLoadMore, numberOfPosts } = props;

  return (
    <div className="post-list-grid wrapper">
      <div className="blog-card-grid">
        {posts.length > 0 &&
          posts.slice(0, numberOfPosts).map(post => <BlogCard key={post.uid} data={post.data} uid={post.uid} />)}
        <OnVisible bounce onChange={onLoadMore} />
      </div>
    </div>
  );
};

export default AllPosts;
