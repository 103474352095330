import React from 'react';
import { Arrow } from 'components';
import { Link } from 'gatsby';
import lemmyPlusYou from '../../images/lemmy-plus-you.svg';
import './styles.scss';

const Plain = props => {
  const { data } = props;
  const { primary } = data;
  const { content } = primary;

  const arrowLabel = (content && content.text) || "Let's get started";

  return (
    <section className="plain-container">
      <div className="wrapper plain-wrapper">
        <Link className="plain-link" to="/brief-us">
          <img className="plain-graphic" src={lemmyPlusYou} alt="Lemmy and you" />
          <Arrow label={arrowLabel} />
        </Link>
      </div>
    </section>
  );
};

export default Plain;
