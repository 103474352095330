import React from 'react';
import { Arrow, Image, Link } from 'components';
import './styles.scss';

const TwoByOne = props => {
  const { data } = props;
  const { items } = data;

  return (
    <div className="two-by-one">
      <div className="wrapper">
        {items.map((item, index) => {
          const { background_color: backgroundColor = '#FFFFFF', brand_name: brandName, image, link } = item;

          const hasImage = image && (image.url || image.localFile);
          const hasBrandName = brandName && brandName.text;
          const revealContent = hasImage && hasBrandName ? 'reveal-content' : '';

          return (
            <Link
              to={link.url}
              key={brandName.text}
              className={`two-by-one-item item-${index + 1} ${revealContent}`}
              style={{ backgroundColor }}
            >
              {hasImage && <Image image={image} className="two-by-one-item-image" />}
              <div className="two-by-one-title">
                <span>{brandName.text}</span>
                <Arrow alignLeft />
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default TwoByOne;
