import React from 'react';
import './styles.scss';

const Hamburger = ({ onClick, active }) => (
  <button type="button" className={`hamburger ${active ? 'active' : ''}`} onClick={onClick}>
    {active ? 'Close' : 'Open'} Menu
  </button>
);

export default Hamburger;
