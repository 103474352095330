import React from 'react';
import { Arrow, Icon } from 'components';
import './styles.scss';

const ScrollDown = ({ scrollTo }) => {
  return (
    <button className="scroll-down" type="button" onClick={scrollTo}>
      <Arrow label="This way please" vertical />
    </button>
  );
};

export default ScrollDown;
