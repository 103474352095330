import React from 'react';
import { Image } from 'components';
import './styles.scss';

const Wysiwyg = props => {
  const { data } = props;
  const { items, primary } = data;
  const { custom_class: customClass } = primary;

  const hasCustomClass = customClass ? customClass.text : '';

  // ask why this won't work on blog-post

  return (
    <section className="wysiwyg-container">
      <div className={`wrapper wysiwyg-wrapper ${hasCustomClass}`}>
        {items.map(item => {
          const hasImage = item.image && item.image.url;
          return (
            <>
              <div className="post-content" dangerouslySetInnerHTML={{ __html: item.post_text.html }} />
              {hasImage && <Image className="wysiwyg-image" image={item.image} />}
            </>
          );
        })}
      </div>
    </section>
  );
};

export default Wysiwyg;
