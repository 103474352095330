import React, { useState } from 'react';
import OnVisible from 'react-on-visible';
import { Image, Link, Video, Arrow } from 'components';
import './styles.scss';

const GridOneCol = props => {
  const { data } = props;
  const { primary, id } = data;
  const { image, video, tagline, title, link, button_text: buttonText, content, custom_class: customClass } = primary;

  const [visible, setVisible] = useState(false);

  const hasImage = image && image.url;
  const hasTitle = title && title.html;
  const hasLink = link && link.url;
  const hasTagline = tagline && tagline.text;
  const hasButton = buttonText && buttonText.text;
  const hasContent = content && content.text;
  const hasText = hasContent || hasButton || hasTagline || hasTitle;
  const hasVideo = video && video.url;
  const hasCustomClass = customClass ? customClass.text : '';

  const ImageAndCta = hasImage && hasButton;

  // if full length image with button
  if (ImageAndCta)
    return (
      <OnVisible className="wrapper grid-one-col image-cta-variant" onChange={setVisible} id={id}>
        <Link to={hasLink}>
          <div className="grid-one-col-image">
            <Image image={image} />
            <div className="grid-one-col-button-container">
              <Arrow label={buttonText.text} alignLeft />
            </div>
          </div>
        </Link>
      </OnVisible>
    );

  return (
    <OnVisible className="wrapper grid-one-col" onChange={setVisible} id={id}>
      {hasText && (
        <div className={`grid-one-col-content-container ${hasCustomClass}`}>
          {hasTagline && <p className="tagline">{tagline.text}</p>}
          {hasTitle && <div className="title grid-one-col-title" dangerouslySetInnerHTML={{ __html: title.html }} />}
          {hasContent && <p className="grid-one-col-content">{content.text}</p>}
          {hasButton && (
            <div className="grid-one-col-button-container">
              <Link to={(hasLink && link.url) || '/brief-us'} className="button">
                {buttonText.text}
              </Link>
            </div>
          )}
        </div>
      )}
      {hasImage && !hasVideo && (
        <div className="grid-one-col-image">
          <Image image={image} />
        </div>
      )}
      {hasVideo && (
        <div className="grid-one-col-image">
          <Video
            key={hasTitle || 'Lemmy'}
            id={hasTitle || 'Lemmy'}
            playOnDemand={visible}
            autoPlay={false}
            placeholder={image && image.videoPlaceholder && image.videoPlaceholder.childImageSharp.fixed.base64}
            src={hasVideo}
          />
        </div>
      )}
    </OnVisible>
  );
};

export default GridOneCol;
