import React from 'react';
import { Icon, Link } from 'components';
import './styles.scss';

const Breadcrumbs = props => {
  const { data, pageTitle } = props;
  const { primary, items } = data;
  const { prepend } = primary;

  if ((!items || items.length === 0) && (!prepend || prepend === 'None')) {
    return null;
  }

  return (
    <section className="breadcrumbs">
      <div className="wrapper">
        {prepend === 'Our Work' && (
          <div className="breadcrumb">
            <Link className="button" to="/projects">
              Our Work
            </Link>
            <Icon icon="chevronRight" />
          </div>
        )}
        {items.map(item => {
          const title = item.title && item.title.text;
          const url = item.link && item.link.url;
          return (
            <div className="breadcrumb">
              <Link className="button" to={url}>
                {title}
              </Link>
              <Icon icon="chevronRight" />
            </div>
          );
        })}
        <div className="breadcrumb">
          <span className="button active">{pageTitle}</span>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumbs;
