import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link, Image } from 'components';
import './styles.scss';

const projectsQuery = graphql`
  query {
    ...GlobalProjects
  }
`;

const Project = props => {
  const { data, location } = props;
  const { pathname } = location;
  const { primary } = data;
  const { title } = primary;

  const { prismicSettings } = useStaticQuery(projectsQuery);
  const { data: globalData } = prismicSettings;
  const { projects } = globalData;

  const hasTitle = title && title.html;

  return (
    <section className="projects">
      <div className="wrapper">
        {hasTitle && <div className="projects-title" dangerouslySetInnerHTML={{ __html: title.html }} />}
        <div className="projects-grid">
          {projects.map(item => {
            const { brand_name: brandName, image, link } = item;
            const hasImage = image && (image.url || image.localFile);
            const linkUrl = link ? link.url : '/';
            const hasBrandName = brandName && brandName.text;
            const revealContent = hasImage && hasBrandName ? 'reveal-content' : '';
            return (
              <Link
                to={linkUrl}
                className={`projects-item ${revealContent} ${linkUrl === pathname ? 'active' : ''}`}
                key={hasBrandName}
              >
                {hasImage && <Image image={item.image} className="projects-image" />}
                {hasBrandName && <p className="projects-brand-name">{item.brand_name.text}</p>}
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Project;
