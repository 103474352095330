import React from 'react';
import { Icon } from 'components';
import './index.scss';

const Arrow = ({ label, fill = '#000', vertical, alignLeft }) => {
  const alignmentClassName = alignLeft ? 'align-left' : 'align-center';
  const axisClassName = vertical ? 'vertical' : 'horizontal';

  return (
    <div className={`arrow-line ${alignmentClassName} ${axisClassName}`}>
      {label && <p className="arrow-line-label">{label}</p>}
      <div className="arrow-line-container">
        <span className="arrow-line-fill" />
        <Icon icon="chevronRight" fill={fill} className="arrow-line-chevron" title={label} />
      </div>
    </div>
  );
};

export default Arrow;
