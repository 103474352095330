import React from 'react';
import { Link } from 'gatsby';
import './styles.scss';

const BackButton = props => {
  const { data, location } = props;
  const { primary } = data;
  const { back_button_text: backButtonText } = primary;
  const { state: locationState } = location;
  const { prevPath } = locationState || {};

  const hasBackbuttonText = backButtonText && backButtonText.text;

  if (!prevPath) {
    return null;
  }

  return (
    <section className="back-button-container">
      <div className="wrapper back-button-wrapper">
        <Link className="back-button-link button" to={prevPath}>
          {hasBackbuttonText || 'Go back'}
        </Link>
      </div>
    </section>
  );
};

export default BackButton;
