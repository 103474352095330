module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Lemmy', // Default Site Title used for SEO & PWA
  description: 'Example description for SEO', // Default Site Decription used for SEO
  siteName: 'Lemmy', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/logos/logo-1024.jpg', // Default OpenGraph image
  ogLanguage: 'en_AU', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Lemmy', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Lemmy', // Author for schemaORGJSONLD
  themeColor: '#80BFDD',
  backgroundColor: '#FCE100',

  twitter: '', // Twitter Username
  googleTagManagerId: 'GTM-5LNNKP7',
};
