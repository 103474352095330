import React from 'react';
import { Logo, Navigation } from 'components';
import './styles.scss';

const navigationLinks = [
  { to: '#', text: 'Our Work', className: '' },
  { to: '#', text: 'Packaging', className: '' },
  { to: '#', text: 'Branding', className: '' },
  { to: '#', text: 'Learn', className: '' },
  { to: '#', text: 'Contact', className: '' },
];

const Header = () => (
  <header className="header">
    <div className="wrapper">
      <Logo />
      <Navigation classNamePrefix="header" links={navigationLinks} />
    </div>
  </header>
);

export default Header;
