import React, { useState } from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import slugify from 'slugify';
import './styles.scss';

const CategoryNav = ({ active }) => {
  const {
    posts: { nodes: posts },
  } = useStaticQuery(allPostsQuery);

  const categoryFilters = posts.reduce((acc, curr) => {
    if (acc.indexOf(curr.data.category) < 0) {
      acc.push(curr.data.category);
    }
    return acc;
  }, []);

  return (
    <div className="category-nav wrapper">
      <div className="wrapper category-nav-wrapper">
        <div>
          <Link to="/blog" className={`button ${!active ? 'active' : 'inactive'}`}>
            All
          </Link>
          {categoryFilters.map(category => (
            <Link
              to={`/blog/${slugify(`${category}`, { lower: true })}`}
              className={`button ${active === category ? 'active' : 'inactive'}`}
            >
              {category}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

const allPostsQuery = graphql`
  query {
    posts: allPrismicBlogPost(sort: { order: DESC, fields: data___date_published }) {
      nodes {
        uid
        data {
          category
        }
      }
    }
  }
`;

export default CategoryNav;
