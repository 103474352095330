import React from 'react';
import { Image } from 'components';
import './styles.scss';

const BlogHeader = props => {
  const { data } = props;
  const {
    blog_post_title: postTitle,
    hero_image: heroImage,
    hero_background_color: backgroundColor = '#FCE100',
  } = data;

  return (
    <section className="blog-header-container">
      <div className="wrapper blog-header-wrapper">
        <div className="blog-header-content" style={{ backgroundColor }}>
          <div className="blog-header-text">
            <span className="lemmy-said">LEMMY SAID</span>
            {postTitle && (
              <h1 className="blog-header-title">
                <span>{postTitle.text}</span>
              </h1>
            )}
          </div>
          <Image image={{ url: heroImage.url }} alt="blog header image" className="hero-image" />
        </div>
      </div>
    </section>
  );
};

export default BlogHeader;
