import React, { useState, useEffect } from 'react';
import { Hamburger, Logo, Arrow, Icon, Link } from 'components';
import './styles.scss';

const HeaderNav = props => {
  const { location } = props;
  const [showNav, setShowNav] = useState(false); // mobile nav appears
  const [showSublink, setShowSublink] = useState(false); // mobile sublink nav appears
  const [scrollTop, setScrollTop] = useState(0); // set pixels to - from top when shownav = false
  const [bodyLocked, setBodyLock] = useState(false); // lock body while show nav = true

  const arrowLabel = 'start project';

  const navigationLinks = [
    { to: '/projects', text: 'Our Work', className: '' },
    {
      to: '#',
      text: 'Expertise',
      className: '',
      sublinks: [
        { to: '/packaging-design', text: 'Packaging', className: '' },
        { to: '/branding', text: 'Branding', className: '' },
      ],
    },
    {
      to: '#',
      text: 'Learn',
      className: '',
      sublinks: [
        { to: '/about-us', text: 'Lemmy team', className: '' },
        { to: '/our-process', text: 'Our process', className: '' },
        { to: '/blog', text: 'Lemmy Blog', className: '' },
      ],
    },
    { to: '/contact-us', text: 'Contact', className: '' },
  ];

  const toggleNav = event => {
    // setting toggle state for hamburger
    event.preventDefault();
    setShowNav(!showNav);
  };

  const toggleSublinkNav = (event, activeIndex) => {
    // setting toggle state for hamburger sublinks
    event.preventDefault();
    if (activeIndex === showSublink) {
      setShowSublink(false);
    } else {
      setShowSublink(activeIndex);
    }
  };

  const handleEscKey = event => {
    // get rid of nav on esc keydown
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setShowNav(false);
    }
  };

  const lockBody = () => {
    // lock body while show nav true
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    // when show nav false, unlock body
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  // use effect exit menu on sec keydown
  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      unlockBody();
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  // use effect when shownav updated
  useEffect(() => {
    if (showNav && !bodyLocked) lockBody();
    if (!showNav && bodyLocked) unlockBody();
  }, [showNav]);

  const hamburgerOpenClass = showNav === true ? 'hamburger-open' : '';
  const isBriefUs = location && location.pathname === '/brief-us' ? 'hide-nav' : '';

  return (
    <div className={`header-nav ${hamburgerOpenClass} ${isBriefUs}`}>
      <div className="wrapper header-nav-wrapper">
        <Logo className="logo" />
        <Hamburger onClick={toggleNav} active={showNav} />
        <div className="header-nav-container">
          {/* CONTACT DETAILS */}
          <section className="header-nav-left">
            <ul className="header-nav-contact">
              <li className="header-nav-contact-item">
                <Icon icon="phone" title="phone us" />
                02 49131459
              </li>
              <li className="header-nav-contact-item">
                <Icon icon="emailLetter" title="email us" />
                hello@lemmy.com.au
              </li>
              <li className="header-nav-contact-item">
                <Icon icon="locationPin" width={20} height={20} title="contact us" />2 Dick Street, Newcastle West NSW 2302
              </li>
            </ul>
            <button type="submit" className="button black outline">
              <Arrow label={arrowLabel} />
            </button>
          </section>

          {/* NAV LINKS */}
          <ul className="header-nav-list">
            {navigationLinks.map((link, index) => {
              const hasSublinks = link.sublinks;

              const showChevron = hasSublinks ? 'show-chevron' : '';
              const sublinkNavOpen = showSublink === index ? 'show-sublinks' : '';

              const containerClassName = `header-nav-list-item-container ${link.className ||
                ''} ${showChevron} ${sublinkNavOpen}`;

              return (
                <li className={containerClassName} key={link.text}>
                  <Link
                    to={link.to}
                    className="header-nav-list-item"
                    onClick={link.to === '#' ? event => event.preventDefault() : null}
                  >
                    {link.text}
                  </Link>
                  <Link
                    to="#togglenav"
                    className="header-nav-item-toggle"
                    onClick={event => toggleSublinkNav(event, index)}
                  >
                    <Icon icon="chevronRight" width={20} height={20} className="header-nav-list-sublink-arrow" />
                  </Link>
                  {/* SUBLINKS */}
                  {link.sublinks && (
                    <div className="header-nav-list-sublinks">
                      {link.sublinks.map(sublink => (
                        <Link key={sublink.text} to={sublink.to} className="header-nav-list-sublink">
                          {sublink.text}
                        </Link>
                      ))}
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderNav;
